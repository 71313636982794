import styled from "styled-components";
import { FcExpand, FcCollapse } from "react-icons/fc";
import { useState } from "react";

const Externals = () => {
  //Externals visibility state and handler
  const [externals, setExternals] = useState(false);
  const viewExternals = () => {
    setExternals(!externals);
  };

  //Examiner visibility state and handler
  const [examiner, setExaminer] = useState(false);
  const viewExaminer = () => {
    setExaminer(!examiner);
  };
  return (
    <>
      <div>
        <CategoryNameDiv>
          <h3>External Assessor for Faculty Promotion</h3>
          {!externals ? (
            <FcExpand onClick={viewExternals} />
          ) : (
            <FcCollapse onClick={viewExternals} />
          )}
        </CategoryNameDiv>
        <DetailedDiv style={{ display: externals ? "flex" : "none" }}>
          <ul>
            <li>University of Macau, 2015 (to associate professor)</li>
            <li>University of Regina, Canada, 2013 (to professor)</li>
            <li>
              University of Sarjah, United Arab Republic, 2010 (to associate
              professor)
            </li>
            <li>York University, Canada, 2009 (to professor)</li>
            <li>University of Victoria, Canada, 2005 (to professor)</li>
            <li>
              University Kebangsang Malaysia, 1998 (to associate professor)
            </li>
          </ul>
        </DetailedDiv>
      </div>
      <div>
        <CategoryNameDiv>
          <h3>External Examiner for Ph.D. Thesis</h3>
          {!examiner ? (
            <FcExpand onClick={viewExaminer} />
          ) : (
            <FcCollapse onClick={viewExaminer} />
          )}
        </CategoryNameDiv>
        <DetailedDiv style={{ display: examiner ? "flex" : "none" }}>
          <ul>
            <li>
              Saint Mary’s University, Sobey Business School, January 3, 2020
              <br></br>
              <span>Candidate: Michalle Thomason</span>
            </li>
            <li>
              Wilfrid Laurier University, Department of Social Work,January16,
              2020
              <br></br>
              <span>Candidate: Karun Karki</span>
            </li>
            <li>
              Saint Mary’s University, Sobey Business School, November 2017
              <br></br>
              <span>Candidate: Marcelle Nolene Allen</span>
            </li>
            <li>
              University of New Brunswick, Faculty of Education, 2015
              <br></br>
              <span>Candidate: Joan Rostant</span>
            </li>
            <li>
              University of Western Australia, Faculty of Management, 2011
              <br></br>
              <span>Candidate: Molley</span>
            </li>
            <li>
              University of New Brunswick, Civil Engineering, 2010 <br></br>
              <span>Candidate:</span>
            </li>
            <li>
              Murdoch University, Faculty of Business, Australia, 2009
              <br></br>
              <span>Candidate: Mr. Jaturong Sriwongwanna</span>
            </li>
            <li>
              University of Western Ontario, Ivey Business School, London, 2007{" "}
              <br></br>
              <span>Candidate: Peter Voyer</span>
            </li>
            <li>
              Laval University, Quebec City, Faculty of Business Administration,
              2006 <br></br>
              <span>Candidate: Hamid Yaginage</span>
            </li>
            <li>University of Madras, India: 2000, 2003, 2004</li>
          </ul>
        </DetailedDiv>
      </div>
    </>
  );
};

const CategoryNameDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const DetailedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px 5%;

  ul {
    list-style-type: none;
  }

  li {
    background-color: #f0f2f2;
    list-style-position: inside;
    padding: 10px;
    margin-bottom: 20px;
  }

  span {
    margin-left: 20px;
  }
`;

export default Externals;
