import styled from "styled-components";
import { FcExpand, FcCollapse } from "react-icons/fc";
import { useState } from "react";
import Articles from "./Articles";
import BookChapters from "./BookChapters";
import BookReviews from "./BookReviews";
import Books from "./Books";

const Author = () => {
  //Articles visibility state and handler
  const [articles, setArticles] = useState(false);
  const viewArticles = () => {
    setArticles(!articles);
  };

  //Books visibility state and handler
  const [books, setBooks] = useState(false);
  const viewBooks = () => {
    setBooks(!books);
  };

  //Book Chapters visibility state and handler
  const [bookChapters, setBookChapters] = useState(false);
  const viewBookChapters = () => {
    setBookChapters(!bookChapters);
  };

  //Book Reviews visibility state and handler
  const [bookReviews, setBookReviews] = useState(false);
  const viewBookReviews = () => {
    setBookReviews(!bookReviews);
  };

  return (
    <Wrapper>
      <header />
      <AuthorDiv>
        <h1>Author</h1>
        <section>
          I have researched business, economics and management-related topics,
          and published papers and articles in a variety of local, national, and
          internal journals. I have also written many papers for academic books
          as chapters. I have done quite a few book reviews and published them
          in established journals. Here is a list of these publications.
        </section>
        <hr></hr>
        <CategoryNameDiv>
          <h3>Articles Published in Refereed Journals</h3>
          {!articles ? (
            <FcExpand onClick={viewArticles} />
          ) : (
            <FcCollapse onClick={viewArticles} />
          )}
        </CategoryNameDiv>
        <DetailedDiv style={{ display: articles ? "flex" : "none" }}>
          <Articles />
        </DetailedDiv>
        <div>
          <CategoryNameDiv>
            <h3>Books</h3>
            {!books ? (
              <FcExpand onClick={viewBooks} />
            ) : (
              <FcCollapse onClick={viewBooks} />
            )}
          </CategoryNameDiv>
          <DetailedDiv style={{ display: books ? "flex" : "none" }}>
            <Books />
          </DetailedDiv>
        </div>
        <div>
          <CategoryNameDiv>
            <h3>Book Chapters</h3>
            {!bookChapters ? (
              <FcExpand onClick={viewBookChapters} />
            ) : (
              <FcCollapse onClick={viewBookChapters} />
            )}
          </CategoryNameDiv>
          <DetailedDiv style={{ display: bookChapters ? "flex" : "none" }}>
            <BookChapters />
          </DetailedDiv>
        </div>
        <div>
          <CategoryNameDiv>
            <h3>Book Reviews</h3>
            {!bookReviews ? (
              <FcExpand onClick={viewBookReviews} />
            ) : (
              <FcCollapse onClick={viewBookReviews} />
            )}
          </CategoryNameDiv>
          <DetailedDiv style={{ display: bookReviews ? "flex" : "none" }}>
            <BookReviews />
          </DetailedDiv>
        </div>
      </AuthorDiv>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
`;

const AuthorDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 10%;
`;

const CategoryNameDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const DetailedDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 10%;

  li {
    margin-bottom: 20px;
    background-color: #f0f2f2;
    list-style-position: inside;
    padding: 10px;
  }
`;

export default Author;
