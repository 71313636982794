const Books = () => {
  return (
    <ol reversed>
      <li>
        Sharma, Basu and Ambika Adhikari (eds.). 2020.{" "}
        <i>Impact of Covid-19 in Nepal: Issues and Perspectives</i> (Asta-Ja
        USA: Honolulu, Hawaii). ISBN: 978- 9937-0-8011-8.
      </li>
      <li>
        Sharma, Basu and Martin Wielemaker (eds.). 2018.{" "}
        <i>Aspects of Entrepreneurship: Practice and Passion</i> (Cambridge
        Scholars Publishing: Newcastle Upon Tyne), pp. 217. ISBN:
      </li>

      <li>
        Sharma, Basu and Jeff McNally (eds.). 2011.{" "}
        <i>Readings in Canadian Labour Relations</i> (Acton, Mass.: Copley
        Publishing Group).
      </li>
      <li>
        Sharma, Basu. 1996.{" "}
        <i>Industrial Relations in ASEAN: A Comparative Study</i>
        (Fredericton, Canada: Management Futures), pp. 144.
      </li>
      <li>
        Staber, Udo, N. Schaefer, and Basu Sharma (eds.). 1996.{" "}
        <i>Business Networks: Prospects for Regional Development</i> (de
        Gruyter: Berlin), pp. 240.
      </li>
      <li>
        Giles, Anthony and Basu Sharma (eds.). 1987.{" "}
        <i>Canadian Industrial Relations</i> (Littleton, MA: Copley Publishing
        Group), 301 pages.
      </li>
      <li>
        Sharma, Basu. 1985. <i>Aspects of Industrial Relations in ASEAN</i>{" "}
        (Singapore: Institute of Southeast Asian Studies, 1985).{" "}
        <i>ASEAN TSJU KOKU ROSHI SE KANKEI</i>, Japanese translation by Kozo
        Kagawa and published by the Kansai International Institute for the Study
        of Industrial Relations, Doshisha University, Kyoto, Japan, 1987.{" "}
      </li>
    </ol>
  );
};

export default Books;
