const BookReviews = () => {
  return (
    <ol reversed>
      <li>
        “Review,” Keshav Bhattarai and Dennis Conway, “Environmental Issues and
        Problems of Nepal.” In <i>SpotlightNepal</i>, February 4, 2021.
        Available at
        <a
          href="https://www.spotlightnepal.com/2021/02/04/book-review-environmental-issues-and-
        problems-nepal/"
          target="blank"
          rel="noreferrer"
        >
          https://www.spotlightnepal.com/2021/02/04/book-review-environmental-issues-and-
          problems-nepal/
        </a>
      </li>
      <li>
        “Review,” Chris Rowley and John Benson, eds., “Globalization and Labour
        in the Asia Pacific Region,” in{" "}
        <i>Relations Industrielles/Industrial Relations</i>, Vol. 55 (Fall
        2001), pp. 824-827.
      </li>

      <li>
        “Review,” Duncan Campbell, Aurelio Parisotto, Anil Verma, and Asthma
        Lateef, eds., “Regionalization and Labour Market Interdependence in East
        and Southeast Asia,” in <i>Industrial and Labor Relations Review</i>, 53
        (1999), pp. 167-169.
      </li>
      <li>
        “Review,” Hilton L. Root., “Small Countries Big Lessons: Governance and
        the Rise of East Asia,” in <i>Journal of Asian Business</i>, 14,
        1(1999), pp. 98-100.
      </li>
      <li>
        “Review,” Gordon Clarke and Won Bae Kim, eds., “Asian NIEs & the Global
        Economy,” in <i>Journal of Asian Business</i>, 11, 1 (1996), pp.
        110-111.
      </li>
      <li>
        “Review,” S. Frenkel, ed., “Organized Labour in the Asia-Pacific
        Region,” in <i>Comparative Labor Law </i>, 16, No. 1 (1995), pp.
        101-104.
      </li>
      <li>
        “Review,” Y.R.K. Reddy, “Trends, Patterns and Impacts of Strikes” in
        <i>Relations Industrielles/Industrial Relations</i>, Vol. 48, No. 1
        (1993), pp. 201-202.
      </li>
      <li>
        “Review,” Robert Hutchens, David Lipsky and Robert Stern, “Strikers and
        Subsidies,” in <i>Relations Industrielles/Industrial Relations</i>, Vol.
        46, No. 3 (Summer 1991), pp. 670-671.
      </li>
      <li>
        “Review,” Greg Bamber and Russell D. Lansbury, eds., “International and
        Comparative Industrial Relations,” in <i>Journal of Labor Research</i>,
        Vol. XI, No. 2 (Spring 1990), pp. 219-220.
      </li>
      <li>
        “Review,” Baldev R. Sharma, “Not by Bread Alone: A Study of
        Organizational Climate and Employer-Employee Relations in India,” in
        <i>Relations Industrielles/Industrial Relations</i>, Vol. 42, No. 1
        (1988), pp. 208-209.
      </li>
    </ol>
  );
};

export default BookReviews;
