import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`


//Declare Global Style for entire site, colors are accessible by var(*color-name*)
*{
    --white: #F8F8F8;
    --dark-grey: #464646;
    font-family: 'Lato', sans-serif;
        box-sizing: border-box;
    margin: 0;
    
    h1{
        color: #0e0e0e;
        text-align: center;
    font-size: 3em;
    font-weight: 300;
    }

    header{
        background-image: url(./header.jpg);
  width: 100%;
  height: calc(60vh - 120px);
  background-attachment: fixed;
  background-position: 50% 30%;
  background-size: cover;
    }

    section{

  text-align: center;
  padding: 20px;
  font-size: 20px;
    color: #6b877d;
    width: 80%;
    margin: 0 10%;
}
}

main{
    transition: all 0.50s linear;
    height: 100%;
    min-height: calc(100vh - 80px);
}
`;

export default GlobalStyles;
