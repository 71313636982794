import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Image></Image>
      <About>
        <h1>Dr. Basu Sharma, Ph.D.</h1>
        <div>
          I am the founding editor and editor-in-chief of the Journal of
          Comparative International Management. I have been on the editorial
          boards of many academic journals including: the Journal of Management,
          the International Journal of Management Literature, Labour and
          Management in Development, Research and Practice in Human Resource
          Management, Canadian Journal of Administrative Sciences, Workplace
          Review, and Transnational Corporations Review.
        </div>
      </About>
      <MoreAbout>
        <ColorDiv>
          <div>
            I was born in 29 September 1950 in Nepal. I earned a Ph. D. degree
            in Industrial Relations from the University of Illinois at
            Urbana-Champaign, U.S.A. in 1983, and emigrated to Canada in 1984 to
            work at the University of Saskatchewan in Saskatoon as an Assistant
            Professor.
          </div>
          <div>
            I have been a professor of Organizational Studies at the University
            of New Brunswick since 1985. I received the UNB Merit Award for
            Extraordinary Performance in Research, Service, and Teaching in
            1990,1998 and 2005. I also received the Faculty of Business
            Administration’s Excellence in Research Award in November 2006 for
            Outstanding Performance across my career.
          </div>
          <div>
            My intellectual contributions have been in diverse areas of
            business, management and economic development, with over 90
            publications including Aspects of Industrial Relations in ASEAN ,
            originally published by the Institute of Southeast Asian Studies in
            Singapore and later translated and published in Japanese by the
            Kansai Institute of Labour of Doshisa University in Japan.
          </div>
          <button onClick={() => navigate("/about")}>More About Me</button>
        </ColorDiv>
        <Picture src="/basu2.jpg" />
      </MoreAbout>
    </>
  );
};

const Image = styled.div`
  background-image: url(./basu5.jpg);
  width: 100%;
  min-height: calc(100vh - 120px);
  background-attachment: fixed;
  background-position: 75% 30%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: #f8f8f8;
    font-size: 3em;
    margin-top: 32%;
  }
`;

const About = styled.div`
  margin: 50px 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;

  div {
    font-size: 20px;
    color: #6b877d;
    width: 80%;
    margin: 0 10%;
  }

  h1 {
    font-size: 3.5em;
    font-weight: 300;
    color: #0e0e0e;
  }
`;

const MoreAbout = styled.div`
  margin: 50px 10%;
  display: flex;
  justify-content: space-evenly;
  height: 60vh;
`;

const ColorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f0f2f2;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px;
  height: 100%;
  width: 50%;
  font-weight: 300;
  font-size: 0.9em;
  line-height: 1.8em;
  color: #1d1d1d;

  button {
    padding: 10px 20px;
    background-color: #97afa3;
    color: white;
    font-size: 1em;
    width: fit-content;
    border: none;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;

    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

const Picture = styled.img`
  height: 100%;
  width: auto;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
`;

export default Home;
