import styled from "styled-components";

//----------------------//
//---Header Component---//
//----------------------//
const Footer = () => {
  return (
    <FooterDiv>
      <div>
        Find me on{" "}
        <a href="https://www.linkedin.com/in/basu-sharma-b031a328/?originalSubdomain=ca">
          LinkedIn
        </a>{" "}
        and <a href="https://basusharma.wordpress.com/">WordPress</a>
      </div>
      <p>Designed by Susil Sharma ©2023</p>
    </FooterDiv>
  );
};

const FooterDiv = styled.div`
  width: 100%;
  height: fit-content;
  padding: 3% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  background-color: #706f80;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  font-size: 1.1em;
  color: #b8b8bc;

  div {
    width: 100%;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: #f8f8f8;
    cursor: pointer;

    :hover {
      color: #404f4a;
      transition: all 0.2s ease-in-out;
    }

    &.active {
      color: #404f4a;
    }
  }

  p {
    font-size: 0.8em;
    margin-top: 20px;
  }
`;

export default Footer;
