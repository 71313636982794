import styled from "styled-components";
import { useState } from "react";
import { FcExpand, FcCollapse } from "react-icons/fc";

const Editor = () => {
  //Journals visibility state and handler
  const [journals, setJournals] = useState(false);
  const viewJournals = () => {
    setJournals(!journals);
  };

  //Conferences visibility state and handler
  const [conferences, setConferences] = useState(false);
  const viewConferences = () => {
    setConferences(!conferences);
  };

  //Papers visibility state and handler
  const [papers, setPapers] = useState(false);
  const viewPapers = () => {
    setPapers(!papers);
  };

  return (
    <Wrapper>
      <header />
      <EditorDiv>
        <h1>Editor</h1>
        <section>
          I have edited numerous academic journals and papers as well as serving
          as an editor for conferences around the globe.
        </section>
        <hr></hr>
        <CategoryNameDiv>
          <h3>Academic Journals</h3>
          {!journals ? (
            <FcExpand onClick={viewJournals} />
          ) : (
            <FcCollapse onClick={viewJournals} />
          )}
        </CategoryNameDiv>
        <DetailedDiv style={{ display: journals ? "flex" : "none" }}>
          <div>
            <u>Founder and Editor-in-Chief</u>
            <ul>
              Journal of Comparative International Management 1998 - 2021.
            </ul>
          </div>
          <div>
            <u>Editor for Management Education and Development Area</u>
            <ul>Canadian Journal of Administrative Sciences, 1989-1990</ul>
          </div>
          <div>
            <u> Member of Editorial Boards:</u>
            <ul>
              <i>Research and Practice in Human Resource Management</i>{" "}
              (Singapore)
            </ul>

            <ul>
              <i>Journal of Management</i> (National University of Malaysia)
            </ul>
            <ul>
              <i>Labour and Management in Development</i> (Australia)
            </ul>
            <ul>
              <i>Workplace Review</i> (Canada)
            </ul>
            <ul>
              <i>Transnational Corporations Review</i> (Canada)
            </ul>
            <ul>
              <i>Journal of Advanced Management Science</i> (Hong Kong)
            </ul>
            <ul>
              <i>Journal of Interdisciplinary Studies in Education</i> (U.S.A.)
            </ul>
            <ul>
              <i>International Journal of Comparative Management</i> (Canada)
            </ul>
          </div>
        </DetailedDiv>
        <CategoryNameDiv>
          <h3>Conference Proceedings</h3>
          {!conferences ? (
            <FcExpand onClick={viewConferences} />
          ) : (
            <FcCollapse onClick={viewConferences} />
          )}
        </CategoryNameDiv>
        <DetailedDiv style={{ display: conferences ? "flex" : "none" }}>
          <Proceedings>
            8. Sharma, Basu. 2014. <i>International Business</i>, Proceedings of
            the International Business Division, Administrative Sciences
            Association of Canada (ASAC) Conference, pp. 1-99.
          </Proceedings>
          <Proceedings>
            7. Sharma, Basu. 2006. <i>Asia and the Changing Global Economy</i>.
            Proceedings of the 2006 International Conference of Business,
            Economics and Management Disciplines, Beijing: University of Science
            and Technology Beijing, June 22-23.
          </Proceedings>
          <Proceedings>
            6. Sharma, Basu, 2005.{" "}
            <i>Shifting Business Practices and Paradigms</i>. Proceedings of the
            35 th Annual Atlantic Schools of Business Conference, Halifax,
            September 29 - October 1.
          </Proceedings>
          <Proceedings>
            5. Sharma, Basu, 2005.{" "}
            <i>Management Paradigms for the New Economy</i>. Proceedings of the
            2005 International Conference of Business, Economics and Management
            Disciplines, Fredericton, August 23-24.
          </Proceedings>
          <Proceedings>
            4. Sharma, Basu. 2005. <i>Managing in Turbulent Times</i>.
            Proceedings of the 2004 International Conference of Business,
            Economics and Management Disciplines, Fredericton, August 22-23.
          </Proceedings>
          <Proceedings>
            3. Sharma, Basu. 1998.{" "}
            <i>Business Education: Challenges for the Next Millennium</i>{" "}
            (Proceedings of the 27 th Annual Atlantic Schools of Business
            Conference, Web publication:
            <a
              href="http://www.fadmin.unb.ca/asb/proceedings"
              target="_blank"
              rel="noreferrer"
            >
              http://www.fadmin.unb.ca/asb/proceedings.
            </a>
            )
          </Proceedings>
          <Proceedings>
            2. Sharma, Basu. 1988. <i>Management Education and Development</i>,
            Proceedings of the Administrative Sciences Association of Canada
            (ASAC) Conference, Vol. 9, Part 10, pp. 1-120.
          </Proceedings>
          <Proceedings>
            1. Jain, Hem, and Basu Sharma. 1986.
            <i>
              {" "}
              Proceedings of the Conference on Improving Productivity through
              Labour- Management Co-operation
            </i>
            (Fredericton: Faculty of Administration, University of New
            Brunswick).
          </Proceedings>
        </DetailedDiv>
        <div>
          <CategoryNameDiv>
            <h3>AD Hoc Paper Reviewer</h3>
            {!papers ? (
              <FcExpand onClick={viewPapers} />
            ) : (
              <FcCollapse onClick={viewPapers} />
            )}
          </CategoryNameDiv>

          <DetailedDiv style={{ display: papers ? "flex" : "none" }}>
            <div>
              <ul>
                <i>Social Indicators Research</i>
              </ul>
              <ul>
                <i>Economics Bulletin</i>
              </ul>
              <ul>
                <i>
                  International Journal of Environment and Sustainable
                  Development
                </i>
              </ul>
              <ul>
                <i>Psychological Reports</i>
              </ul>
              <ul>
                <i>Personnel Review</i>
              </ul>
              <ul>
                <i>Journal of the Asia Pacific Economy</i>
              </ul>
              <ul>
                <i>Journal of Economic and Administrative Sciences</i>
              </ul>
              <ul>
                <i>Global Economy Journal</i>
              </ul>
              <ul>
                <i>Canadian Journal of Administrative Sciences</i>
              </ul>
              <ul>
                <i>ASEAN Economic Bulletin</i>
              </ul>
              <ul>
                <i>Journal of Labour Research</i>
              </ul>
              <ul>
                <i>Relations Industrielles</i>
              </ul>
              <ul>
                <i>Studies in Comparative International Development</i>
              </ul>
              <ul>
                <i>Transnational Corporations Review</i>
              </ul>
              <ul>
                <i>Asia Pacific Journal of Management</i>
              </ul>
              <ul>
                <i>Journal of Asia-Pacific Business</i>
              </ul>
              <ul>
                <i>International Economics</i>
              </ul>
            </div>
          </DetailedDiv>
        </div>
      </EditorDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const EditorDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 10%;

  ul {
    list-style-type: none;
  }
`;

const CategoryNameDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const DetailedDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 5%;

  ul {
    margin: 20px 0;
    background-color: #f0f2f2;
    list-style-position: inside;
    padding: 10px;
  }
`;

const Proceedings = styled.div`
  background-color: #f0f2f2;
  padding: 10px;
`;
export default Editor;
