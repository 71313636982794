import styled from "styled-components";
import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroPic />
      <AboutDiv>
        <h1>Hello World!</h1>
        <section>
          My name is Basu Sharma (born 29 September 1950 in Nepal). I earned a
          Ph. D. degree in industrial relations from the University of Illinois
          at Urbana-Champaign, U.S.A. in 1983, and emigrated to Canada in 1984
          to work at the University of Saskatchewan in Saskatoon as an Assistant
          Professor.
        </section>
        <hr></hr>
        <div>
          You may be wondering what happened to me between 1950 and 1983. In
          fact, several things happened. Here is a summary of those happenings.
          I was born with breathing problems. My parents were not sure whether I
          will survive. With the help of a local sorcerer (Dhami), I was able to
          breathe normally and thus survived. I was the only male child in the
          family. The members were therefore jubilant about my survival. Not
          only that, they poured so much love and affection on me that I was
          almost at the threshold of being spoilt kid. But life took a different
          turn: my mom passed away in July 1954 at the age of 38. She left six
          children behind to my father to look after—five daughters ranging in
          age from one and half years to sixteen and a son, two months short of
          four. I was raised by my father.
        </div>
        <div>
          Since there was no public school in my village at that time, I was
          home-schooled until grade four when a primary school was founded in
          the nearby village called Okherbote. I did grade five there and got a
          double promotion. So I skipped grade six and moved to grade seven at a
          middle school in Bokhim, where my maternal uncle lived. I lived with
          his family to complete grade seven and joined Bidyodaya High School
          for grades eight, nine and half of ten. I completed grade ten at
          Dharan Night high school and sat for the S.L.C. exam. I did my
          Intermediate of Arts from Mechi College Bhadrapur, Bachelor of Arts
          with major in Economics and Special English from Patan College, and
          Master in English literature from the Tribhuvan University, Kathmandu.
          I was then awarded a fellowship to study for Ph. D. in linguistics by
          the Kathmandu branch of the Summer Institute of Linguistics (SIL). I
          studied linguistics under the supervision of Dr. Austin Hale for a
          year and half, successfully defended the thesis proposal and was well
          underway towards earning a doctoral degree from the T.U. But because
          of a political problem between the Government of Nepal and the SIL,
          key people associated with the SIL including Dr. Hale were asked to
          leave the country within three months. Dr. Hale left for the
          Philippines. My Ph.D. program collapsed. I started to teach in the
          English department at the central campus. I had given up the idea of a
          Ph.D. Suddenly, I got a call from the director of The SIL, and in our
          conversation he mentioned that he had secured an application form for
          me from the University of Illinois. He wanted me to go to see him. I
          was excited and went to see him right away. He gave me the application
          and asked me to complete and send it. So I did. And my application was
          successful. I joined the department of linguistics at the University
          of Illinois in January 1977. I studied linguistics for a year and
          changed to labour and industrial relations. I earned completed
          master’s degree in 1979 and Ph.D. degree in 1983.
        </div>

        <FamilyDiv>
          <ColorDivTwo>
            <div>
              I was granted a fellowship from the Institute of South-east Asian
              Studies in Singapore for a year. I went to Singapore in June 1983,
              and did the fellowship for six months, converting my doctoral
              thesis into a research monograph for publication. I did that and
              moved to Saskatoon, Saskatchewan, Canada in December 1983. Going
              from Singapore to Saskatoon in late December was quite a shock. It
              was brutally cold in Saskatoon. Also, the appointment was on a
              two-year term, not a tenurable one. So started to look for a
              faculty position elsewhere. I still did not have a landed
              immigrant status. So, I was at some disadvantage in the job
              market. Eventually, I got a job as an assistant professor of
              industrial relation/human resource management at the University of
              New Brunswick in Fredericton.
            </div>
            <div>
              In June 1985, my wife Bina and I moved to Fredericton with our two
              lovely daughters Mana and Monica. I have been professor of
              organizational studies at the University of New Brunswick since
              then. I received the UNB Merit Award for extraordinary performance
              in research, service, and teaching during 1990, 1998 and 2005. I
              also received the Faculty of Business Administration's Excellence
              in Research Award in November 2006 for outstanding performance
              across the career.
            </div>
          </ColorDivTwo>
          <FamilyPicture src="/basunbina.jpg" />
        </FamilyDiv>
        <div>
          I have held appointments at the National University of Singapore,
          Tribhuvan University (Kathmandu, Nepal), and University of
          Saskatchewan. I have been a consultant for the International Labour
          Organization, the Singapore National Employers Federation, and the
          Singapore Institute of Labour. My intellectual contributions have been
          in diverse areas of business, management and economic development,
          with over 90 publications including Aspects of Industrial Relations in
          ASEAN, originally published by the Institute of Southeast Asian
          Studies in Singapore and later translated and published in Japanese by
          the Kansai Institute of Labour of Doshisa University in Japan. I
          founded the Journal of Comparative International Management in 1997
          and served as its editor-in-chief until 2021. I was actively involved
          in the flagship Canadian professional organization of business and
          management professors in Canada-the Administrative Sciences
          Association of Canada, serving in various capacities such as a member
          of the executive, awards chair and the Chair of the Management
          Education and International Business divisions. Also, I was President
          of the Atlantic Schools of Business Society for 2015-2016.
        </div>
      </AboutDiv>
    </>
  );
};

const AboutDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 200px;
  color: #61727c;
`;

const HeroPic = styled.div`
  background-image: url(./header.jpg);
  width: 100%;
  height: calc(60vh - 120px);
  background-attachment: fixed;
  background-position: 50% 30%;
  background-size: cover;
`;

const ColorDivTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f0f2f2;
  padding: 20px;
  height: 100%;
  width: 50%;
  font-weight: 300;
  font-size: 0.9em;
  line-height: 1.8em;
  color: #1d1d1d;
`;

const FamilyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const FamilyPicture = styled.img`
  height: 450px;
  width: auto;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-right: 10%;
`;

export default About;
