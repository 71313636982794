import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";

const BlogPost = () => {
  const blogId = useParams();
  const [post, setPost] = useState([]);

  useEffect(() => {
    // GET all blog posts
    fetch(`/blog/${blogId.blogId}`)
      .then((response) => response.json())
      .then((data) => {
        setPost(data.data);
      })
      .catch((err) => console.log("Error: ", err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(post);
  return (
    <Wrapper>
      <BlogDiv>
        {post.length === 0 ? (
          <h1>Loading..</h1>
        ) : (
          <>
            <h1>{post.title}</h1>
            <hr></hr>
            <PostDiv>{post.post}</PostDiv>
            <hr></hr>
            <Link to="/blog">Return To Blog</Link>
          </>
        )}
      </BlogDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const PostDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  white-space: pre-wrap;
`;

const BlogDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 10%;
`;

export default BlogPost;
