import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import Header from "./Header";
import Home from "./Home";
import About from "./About";
import Author from "./Author/Author";
import Editor from "./Editor";
import Professional from "./Professional/Professional";
import DigitalRecords from "./DigitalRecords";
import Blog from "./Blog/Blog";
import BlogPost from "./Blog/BlogPost";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {
  return (
    <div>
      <Router>
        <Header />
        <GlobalStyles />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/author" element={<Author />} />
          <Route path="/editor" element={<Editor />} />
          <Route path="/professional" element={<Professional />} />
          <Route path="/records" element={<DigitalRecords />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blogId" element={<BlogPost />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
