import styled from "styled-components";

const DigitalRecords = () => {
  return (
    <Wrapper>
      <header />
      <RecordsDiv>
        <h1>Digital Public Record of Scholarly Activities</h1>

        <hr></hr>

        <ul>
          <li>
            <div>Google Scholar</div>
            <a
              href="https://scholar.google.com/citations?user=BML7JBYAAAAJ&hl=en"
              target="blank"
              rel="noreferrer"
            >
              https://scholar.google.com/citations?user=BML7JBYAAAAJ&hl=en
            </a>
          </li>
          <li>
            <div>Orcid</div>
            <a
              href="https://orcid.org/0000-0003-1362-0295"
              target="blank"
              rel="noreferrer"
            >
              https://orcid.org/0000-0003-1362-0295
            </a>
          </li>
          <li>
            <div>Scopus Author ID:</div>
            <div>7403255784</div>
          </li>
          <li>
            <div>IDEAS</div>
            <a
              href="https://ideas.repec.org/e/psh132.html"
              target="blank"
              rel="noreferrer"
            >
              https://ideas.repec.org/e/psh132.html
            </a>
          </li>
          <li>
            <div>ResearchGate</div>
            <a
              href="https://www.researchgate.net/profile/Basu-Sharma"
              target="blank"
              rel="noreferrer"
            >
              https://www.researchgate.net/profile/Basu-Sharma
            </a>
          </li>
        </ul>
      </RecordsDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const RecordsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 10%;

  li {
    margin-bottom: 20px;
    background-color: #f0f2f2;
    padding: 10px;
    margin-bottom: 20px;
  }
`;

export default DigitalRecords;
