const BookChapters = () => {
  return (
    <ol reversed>
      <li>
        Sharma, Basu and Ambika Adhikari. 2020. Issues and Perspectives on
        Covid-19 and Nepal. In <i>Covid-19 Pandemic and Nepal</i>, B. Sharma and
        A. Adhikari, eds. Honolulu, Hawaii: Asta-Ja USA. pp. 1-11. [ISBN:
        978-9937-0-8011-8]
      </li>
      <li>
        Sharma, B. 2020. International Education as a Human Resource Strategy:
        “A Citizen Model” for Canadian Immigration Policy. In{" "}
        <i>International Education as Public Policy in Canada</i>, Tamtik, M.,
        Trilokekar, R. D., and Jones, G. A. (eds.), McGill-Queen’s University
        Press: Kingston, ON, pp. 69-89. [ISBN: 9780228001768].
      </li>
      <li>
        Gajurel, Dinesh and Sharma, B. 2020. Diaspora and Capacity Building in
        Higher Education in Nepal: An Entrepreneurial Perspective. In{" "}
        <i>Higher Education in Nepal: Policies and Perspectives</i>, Krishna
        Bista, Shyam Sharma and Rosalind Latiner Raby (eds.), Routledge: New
        York, pp. 100-110.
      </li>
      <li>
        Sharma, B. 2019. Remittances and Capacity Building Issues in Nepal. In
        <i>
          Capacity Building in Developing and Emerging Countries: From Mindset
          Transformation to Promoting Entrepreneurship and Diaspora Involvement
        </i>
        , E. Chrysostome (ed.), Springer Nature Switzerland, Contributions to
        Management Science Series, pp. 243-263. [Doi:
        10.1007/978-3-030-16740-0_9]
      </li>
      <li>
        Wielemaker, M. and Sharma, B. 2018. Introduction: Passion and Practice.
        In Sharma, Basu and Wielemaker, Martin (eds.). 2018.{" "}
        <i>Aspects of Entrepreneurship: Practice and Passion</i> (Cambridge
        Scholars Publishing: Newcastle Upon Tyne), pp. 1-26.
      </li>
      <li>
        Sharma, Basu. 2014. Challenges of Disruptive Political Changes for
        Business Development in Nepal. In{" "}
        <i>
          Building Business in Emerging and Developing Countries: Challenges and
          opportunities
        </i>
        , Elie Chrysostome and Rick Molz (eds.), Routledge, pp. 7- 17.
      </li>
      <li>
        Rashid, M., Sharma, Basu, and Jamal, M. 2011. “Customers' Demand
        Aversion to Polluting Product and Firms' Output/Pricing Decision” in
        <i>
          Balancing Profitability and Sustainability: Shaping the Future of
          Business
        </i>
        , eds., Margaret A. Goralski, H. Paul Leblanc, III, and Marjorie G.
        Adams (Bestville, MD: International Academy of Business Disciplines),
        pp. 143-148. ISBN: 1-889754-16-1
      </li>
      <li>
        Sharma, Basu. 2010. “Language and Cultural Barriers” in{" "}
        <i>Encyclopaedia of Case Study Research</i>, Vol. 2, eds. Albert J.
        Mills, Gabrielle Durepos and Elden Wiebe (London : Sage Publication),
        pp. 519-521.
      </li>
      <li>
        Sharma, Basu. 2010. “Postpositivism” in{" "}
        <i>Encyclopaedia of Case Study Research</i>, Vol. 2, eds. Albert J.
        Mills, Gabrielle Durepos and Elden Wiebe (London : Sage Publication),
        pp. 701-703.
      </li>
      <li>
        Arcelus, Francisco, Basu Sharma and Gopalan Srinivasan, 2006. “The human
        development index adjusted for efficient resource utilization” in
        <i>Inequality, Poverty and Well-being</i>, ed., Mark McGillivray,
        London: Palgrave/Macmillan in association with United Nations
        University, pp. 177-193.
      </li>
      <li>
        Sharma, Basu. 2003. “Labour Rights in Negara Brunei Darussalam” in{" "}
        <i>
          Asia Pacific Labour Law Review: Workers' Rights for the New Century
        </i>
        , edited by Stephen Frost, Omana George and Ed Shepherd (Hong Kong: Asia
        Monitor Resource Center), pp. 65-71.
      </li>
      <li>
        Sharma, Basu. 1996. “Dispute Resolution: Canada and Singapore Compared”
        in <i>Industrial Relations in Singapore Industry</i>, edited by Chew
        Soon Beng and Rosalind Chew (Addison-Wesley Publishing Company), pp.
        243-261.
      </li>
      <li>
        Sharma, Basu, Anil Verma, and Sarosh Kuruvilla. 1996. “Strategic
        Economic Cooperation and Employment Relations Issues: The Case of Growth
        Triangle,” in{" "}
        <i>Business Networks: Prospects for Regional Development</i>, Udo
        Staber, Norbert Schaefer, and Basu Sharma, eds. (Berlin: Walter de
        Gruyter), pp. 132-147.
      </li>
      <li>
        Sharma, Basu. 1992, “Labour Management Cooperation for Sound Labour
        Relations,” in{" "}
        <i>Employers' Organizations on Sound Labour Relations Practices</i>,
        Bangkok: International Labour Office, pp. 11-31.
      </li>
      <li>
        Sharma, Basu. 1991. “Industrialization and Strategy Shifts in Industrial
        Relations,” in Shaun Tyson and Chris Brewster (eds.),{" "}
        <i>International Comparisons in Human Resource Management</i>, London:
        Pitman Books Limited, pp. 92-109.
      </li>
      <li>
        Sharma, Basu. 1990. “Trade Implications of the Asian NIEs Industrial
        Relations Strategies,” in George T. Yu (ed.),{" "}
        <i>American Foreign Policy Toward the Newly Industrialized Economies</i>
        , Urbana, IL: University of Illinois Press, pp. 69-93.
      </li>
      <li>
        Sharma, Basu. 1990. “Multinationals and the ASEAN Economies,” in Mekki
        Mtewa (ed.),{" "}
        <i>
          International Development and Alternative Futures: The Coming
          Challenges
        </i>
        , New Delhi: Allied Publishers Ltd., pp. 422-434.
      </li>
      <li>
        Sharma, Basu. 1989. “A Cross-National Study of Strikes and Economic
        Growth,” in Jean Boivin (ed.),{" "}
        <i>Some Aspects of International Industrial Relations</i>, Quebec City:
        Canadian Industrial Relations Association, pp. 224-235.
      </li>
    </ol>
  );
};

export default BookChapters;
