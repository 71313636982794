import styled from "styled-components";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [toSend, setToSend] = useState({
    name: "",
    message: "",
    reply_to: "",
  });

  const [status, setStatus] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send("service_lvzj9he", "template_kih3s1n", toSend, "5v1qIV0TDyH-r7wcF")
      .then(
        (response) => {
          console.log("SUCCESS!", response);
          setToSend({ ...toSend, [e.target.name]: e.target.value });
          setStatus("SUCCESS");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      setTimeout(() => {
        setStatus("");
      }, 3000);
    }
  }, [status]);

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Wrapper>
      <ContactDiv>
        <h1>Contact</h1>
        <hr></hr>

        <form onSubmit={onSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              placeholder="Name"
              required
              value={toSend.name}
              onChange={handleChange}
            />
          </label>{" "}
          <label>
            E-mail Address:
            <input
              type="text"
              name="reply_to"
              placeholder="Email Address"
              required
              value={toSend.reply_to}
              onChange={handleChange}
            />
          </label>
          <label>
            Message:
            <Message
              type="text"
              name="message"
              placeholder="Your message"
              required
              value={toSend.message}
              onChange={handleChange}
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </ContactDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const ContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 50px 10%;

  form {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 60%;
    height: fit-content;
    background-color: #f0f2f2;
    padding: 20px;

    input {
      width: 100%;
      border: none;
      margin-top: 10px;
      padding: 20px;
    }

    button {
      color: white;
      background-color: #97afa3;
      padding: 10px 20px;
      width: fit-content;
      border: none;
      border-radius: 5px;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;

      :hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;

const Message = styled.input`
  height: 15vh;
`;

export default Contact;
