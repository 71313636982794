import styled from "styled-components";
import Externals from "./Externals";
import Meetings from "./Meetings";
import Organizations from "./Organizations";

const Professional = () => {
  return (
    <Wrapper>
      <header />
      <AuthorDiv>
        <h1>Professional</h1>
        <section>
          I am mindful of the fact that the public expects professional services
          of a professor. And as a professor of business and management, I was
          also engaged in providing several professional services to the
          university and community at large. Here is a summary of some of these
          activities.
        </section>
        <hr></hr>
        <Externals />
        <Organizations />
        <Meetings />
      </AuthorDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const AuthorDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 10%;
`;

const IntroDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-bottom: 5vh;
  align-items: center;
  text-align: center;
  font-size: 18px;
  div {
    width: 80%;
    text-align: center;
  }
`;

export default Professional;
