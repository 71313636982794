import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

const Blog = () => {
  const [blogposts, setBlogposts] = useState([]);

  useEffect(() => {
    // GET all blog posts
    fetch("/blog")
      .then((response) => response.json())
      .then((data) => {
        setBlogposts(data.data);
      })
      .catch((err) => console.log("Error: ", err));
  }, []);

  return (
    <Wrapper>
      <header />
      <BlogDiv>
        <h1>Blog</h1>
        <section>
          This space is for my musings. You can browse them by clicking below.
        </section>
        <hr></hr>
        <>
          {blogposts.length > 0 ? (
            blogposts.map((post) => {
              console.log(post._id);
              return (
                <PostDiv key={post._id}>
                  <PostLink to={`/blog/${String(post?._id)}`}>
                    {post.title}
                  </PostLink>{" "}
                  <h3> / </h3>
                  <>{post.date}</>
                </PostDiv>
              );
            })
          ) : (
            <>loading</>
          )}
        </>
      </BlogDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const PostDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  white-space: pre-wrap;
`;

const BlogDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 10%;

  li {
    margin-bottom: 20px;
    /* background-color: #d8e3eb; */
    /* list-style-position: inside; */
    /* padding: 10px; */
  }
`;

const PostLink = styled(NavLink)`
  color: black;
  font-weight: 400;
  font-size: 2em;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #7e9c92;
    transition: all 0.2s ease-in-out;
  }
`;

export default Blog;
