import styled from "styled-components";
import { FcExpand, FcCollapse } from "react-icons/fc";
import { useState } from "react";

const Organizations = () => {
  //Organizations visibility state and handler
  const [organizations, setOrganizations] = useState(false);
  const viewOrganizations = () => {
    setOrganizations(!organizations);
  };
  return (
    <div>
      <CategoryNameDiv>
        <h3>Service to Professional Organizations</h3>
        {!organizations ? (
          <FcExpand onClick={viewOrganizations} />
        ) : (
          <FcCollapse onClick={viewOrganizations} />
        )}
      </CategoryNameDiv>
      <DetailedDiv style={{ display: organizations ? "flex" : "none" }}>
        <u>President</u>
        <ul>
          Atlantic Schools of Business Conference Society<br></br> 2016
        </ul>
        <u>President-elect/Conference Chair</u>
        <ul>
          <li>
            2015 Atlantic Schools of Business<br></br>UNBF, September 25-27,
            2015
          </li>
        </ul>
        <u>Division Chair</u>
        <ul>
          <li>
            International Business<br></br>
            2016 Conference of the Administrative Sciences Association of Canada
            <br></br>
            Edmonton, Alberta, 8-11 June, 2016.
          </li>
        </ul>
        <u>Division Coordinator</u>
        <ul>
          <li>
            International Business<br></br>
            2015 Conference of the Administrative Sciences Association of Canada
            <br></br>
            Halifax, 14-16, 2015.
          </li>
        </ul>
        <u>Academic Editor</u>
        <ul>
          <li>
            International Business<br></br>
            2014 Conference of the Administrative Sciences Association of Canada
            <br></br>
            Muskoka, Ontario, May 9-13, 2014.
          </li>
        </ul>
        <u>Area Chair</u>
        <ul>
          <li>
            Human Resource Management and Industrial Relations<br></br>
            2011 Atlantic Schools of Business Conference, Charlottetown, PEI
          </li>
        </ul>
        <u>Area Chair</u>
        <ul>
          <li>
            Human Resource Management and Industrial Relations<br></br>2010
            Atlantic Schools of Business Conference, Halifax
          </li>
        </ul>
        <u>Area Chair</u>
        <ul>
          <li>
            Human Resource Management and Industrial Relations<br></br>2009
            Atlantic Schools of Business Conference, Moncton
          </li>
        </ul>
        <u>Conference Co-Chair</u>
        <ul>
          <li>
            2006 International Conference of Business, Economics and Management
            Disciplines
            <br></br>
            University of Science and Technology Beijing. P.R.C., June 23-25,
            2006.
          </li>
        </ul>
        <u>Conference Chair</u>
        <ul>
          <li>
            2005 Atlantic Schools of Business<br></br>Halifax, September
            29-October1, 2005
          </li>
        </ul>
        <u>Conference Chair</u>
        <ul>
          <li>
            2005 International Conference of Business, Economics and Management
            Disciplines
            <br></br>Fredericton, August 19-20, 2005
          </li>
        </ul>
        <u>Conference Chair</u>
        <ul>
          <li>
            2004 International Conference of Business, Economics and Management
            Disciplines
            <br></br>Fredericton, August 20-21, 2004
          </li>
        </ul>
        <u>Track Chair</u>
        <ul>
          <li>
            Management I: Labour Relations<br></br>American Society for Business
            and Behavioural Sciences, 2000
          </li>
        </ul>
        <u>Conference Chair</u>
        <ul>
          <li>27th Atlantic Schools of Business Conference, 1997</li>
        </ul>
        <u>Awards Chair</u>
        <ul>
          <li>
            Administrative Sciences Association of Canada<br></br>1994 - 1995
          </li>
        </ul>
        <u>Member-at-Large, Executive Board</u>
        <ul>
          <li>Administrative Sciences Association of Canada, 1993-1995</li>
        </ul>
        <u>Resource Person</u>
        <ul>
          <li>
            UNBSJ Project on Human Resource Development<br></br>
            Workshop on Human Resource Management Hanoi, Vietnam, May 1995
          </li>
        </ul>
        <u>Member of the Internationalization Task Force, 1990-91, 1991-92</u>
        <ul>
          <li>
            Management Education and Development Division Academy of Management,
            USA
          </li>
        </ul>
        <u>Resource Person, May 1990</u>
        <ul>
          <li>
            CIDA-UNB-AIMS Project<br></br>
            Workshop on “Management Education in the Year 2000 and Beyond”
            <br></br>New Delhi, India, May 18-19, 1990
          </li>
        </ul>
        <u>Resource Person, Summer 1989</u>
        <ul>
          <li>
            CIDA-CFDMAS-ASEAN Project<br></br>Workshop on Human Resource
            Management<br></br>Bangkok, Thailand, April 28, 1989 - May 14, 1989
          </li>
        </ul>
        <u>Member of the Board of Directors, 1988-89</u>
        <ul>
          <li>Administrative Sciences Association of Canada</li>
        </ul>
        <u>Division Chairperson, 1988-89</u>
        <ul>
          <li>
            Management Education and Development Division<br></br>Administrative
            Sciences Association of Canada
          </li>
        </ul>
        <u>Program Chairperson, 1987-88</u>
        <ul>
          <li>
            Management Education and Development Division<br></br>Administrative
            Sciences Association of Canada
          </li>
        </ul>
        <u>Session Organizer, 1986</u>
        <ul>
          <li>
            Research Forum on International Business<br></br>Sixteenth Annual
            Atlantic Schools of Business Conference
          </li>
        </ul>
      </DetailedDiv>
    </div>
  );
};

const CategoryNameDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const DetailedDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 5%;

  ul {
    list-style-type: none;

    background-color: #f0f2f2;
    list-style-position: inside;
    padding: 10px;
    margin-bottom: 20px;
  }

  span {
    margin-left: 20px;
  }
`;

export default Organizations;
