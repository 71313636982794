import styled from "styled-components";
import { FcExpand, FcCollapse } from "react-icons/fc";
import { useState } from "react";

const Meetings = () => {
  //Meetings visibility state and handler
  const [meetings, setMeetings] = useState(false);
  const viewMeetings = () => {
    setMeetings(!meetings);
  };

  //Technical visibility state and handler
  const [technical, setTechnical] = useState(false);
  const viewTechnical = () => {
    setTechnical(!technical);
  };
  return (
    <>
      <div>
        <CategoryNameDiv>
          <h3>Presenter at Professional and Technical Meetings</h3>
          {!meetings ? (
            <FcExpand onClick={viewMeetings} />
          ) : (
            <FcCollapse onClick={viewMeetings} />
          )}
        </CategoryNameDiv>
        <DetailedDiv style={{ display: meetings ? "flex" : "none" }}>
          <ul>
            Sharma, Basu. 2019. International education as human resource
            strategy in Canada. Paper presented at the 2019 Ottawa Research
            Conference, August 3-4, 2019. University of Ottawa, Ottawa.
          </ul>
          <ul>
            Sharma, Basu. 2015. “Disruptive Political Changes and Foreign Direct
            Investment in Nepal,” Paper presented at the 2015 Annual Conference
            of the Academy of International Business, June 27-30, 2015,
            Bangalore, India.
          </ul>
          <ul>
            Sharma, Basu. 2014. “Age, Education, Experience and Negotiation
            Orientation,” 2014 Annual Conference of the Academy of International
            Business-US North-East Chapter, November 14–15, 2014. Providence,
            RI.
          </ul>
          <ul>
            Basu Sharma, Gopalan Srinivasan, Francisco Arcelus and Murari
            Subedi. 2014. Foreign Capital and Human Development in Asia. Paper
            presented at the 2014 ASAC conference, May 9-13, 2014. Muskoka,
            Ontario, Canada.
          </ul>
          <ul>
            Sharma, Basu and Joe Abekah. 2013. The effect of foreign direct
            investment and pollution on economic growth of African and South
            American countries. Paper presented at the 2013 ASAC conference,
            June7-11, 2013. Calgary, Alberta, Canada.
          </ul>
          <ul>
            Sharma, Basu, Rashid, M., Drira, M. 2011. “Hegemonic Power and
            Executive Compensation,” Paper presented at the 2011 Atlantic
            Schools of Business Conference, September 30-October 2, 2011.
            Charlottetown, PEI.
          </ul>
          <ul>
            Sharma, Basu and Joe Abekah. 2007. “Foreign direct investment and
            economic growth of selected African and South American Countries,”
            presented to the 17 th International Conference of International
            Trade and Finance Association, Miami, May 16-18, 2007.
          </ul>
          <ul>
            Sharma, Basu, David Wan, Ong Chin Huat and Anthony E. Smith. 1998.
            “Executive Compensation in Australia, Canada and Singapore: A
            Comparative Study,” presented to the Study Group on Pay Systems at
            the 11 th World Congress of the International Industrial Relations
            Associations, Bologna, Italy, September 22-26, 1998.
          </ul>
          <ul>
            Sharma, Basu. 1997. “Executive Compensation in Leading Canadian
            Companies,” paper presented to the Canadian Industrial Relations
            Association's 1997 Annual Conference, St. John's, Newfoundland,
            Canada, June 9-12, 1997.
          </ul>
          <ul>
            Otuteye, Eben and Basu Sharma. 1997. “A Transformational-Generative
            Model of Entrepreneurship,” paper presented to the 1997 Annual
            Conference of the Administrative Sciences Association of Canada
            1997, Entrepreneurship Division, pp. 11.
          </ul>
          <ul>
            Otuteye, Eben and Basu Sharma. 1997. “An Alternative Model of Micro
            and Macro Entrepreneurship,” paper presented to the 14th Annual
            Conference of the Canadian Council for Small Business and
            Entrepreneurship, Vancouver, BC, October 16 - 18, 1997.
          </ul>
          <ul>
            Sharma, Basu. 1996. “Continuities and Changes in Industrial
            Relations in ASEAN,” paper presented to the Canadian Industrial
            Relations Association's 1996 Annual Conference, St. Catharines,
            Ont., Canada, May 29-31, 1996.
          </ul>
          <ul>
            Sharma, Basu. 1996. “An Overview of Alternative Economic
            Development,” presented to the Ten Days for World Development
            Special Conference, Nashwaaksis United Church, Fredericton, New
            Brunswick, March 9.
          </ul>
          <ul>
            Sharma, Basu, Anil Verma, and Sarosh Kuruvilla. 1994. “Strategic
            Economic Cooperation and Employment Relations Issues: The Case of
            Growth Triangle,” Conference on Changing Employment Relations in
            Asia, Taipei, June 28-30, 1994 [Sponsored by the Chung-Hua
            Institution of Economic Research and Massachusetts Institute of
            Technology].
          </ul>
          <ul>
            Sharma, Basu. 1994. “Aspects of Internationalization of Management
            Education,” presented to the 1994 Administrative Sciences
            Association of Canada Annual Conference, Halifax, Nova Scotia, June
            25-27.
          </ul>
          <ul>
            Sharma, Basu and Udo Staber. 1993. “Business Networks and Industrial
            Districts: Issues for Labour,” Conference on Business Clusters and
            Networks, November 18-20, Fredericton, New Brunswick.
          </ul>
          <ul>
            Verma, Anil and Basu Sharma. 1992. “The Role of Strategic Choice in
            Employment Relations in the Context of Economic Development,”
            presented to the Third Biennial Southeast Asia Business Research
            Conference, May 16-17, University of Michigan, Ann Arbor, MI.
          </ul>
          <ul>
            Sharma, Basu. 1989. “The Role of Labour Relations Strategies in
            South Korea's Industrialization,” 1989 Annual Meetings of the
            International Academy of Management and Marketing, March 31-April 2,
            Pittsburgh, Pennsylvania.
          </ul>
          <ul>
            Withane, S., and Sharma, Basu. 1987. “Management Education and
            Supervisory Training in Major Canadian Corporations,” presented to
            the 6th World Congress of the World Council of Comparative Education
            Societies, Rio de Janeiro, Brazil, July 6-10.
          </ul>
          <ul>
            Sharma, Basu and S. Withane. 1986. “Influence of Proximity Factors
            in Multinationals' Investment Abroad: An Empirical Study,” presented
            to the International Business Division of the Academy of Management
            46 th Annual Meeting, Chicago, USA, August 13-16, 1986.
          </ul>
          <ul>
            Sharma, Basu, and S. Withane. 1986. “Transferring Management
            Education: An Alternative Paradigm,” presented to the 30th Annual
            Meeting of the Comparative and International Education Society,
            Toronto, March 13-16.
          </ul>
          <ul>
            Sharma, Basu. 1982. “Foreign Capital and Structural Transformation
            of Semi- Industrialized Asian Countries,” paper presented to the
            1982 Annual Conference of the Western Economic Association, Los
            Angeles, July 15-19.
          </ul>
        </DetailedDiv>
      </div>
      <div>
        <CategoryNameDiv>
          <h3>Panelist in Professional and Technical Meetings</h3>
          {!technical ? (
            <FcExpand onClick={viewTechnical} />
          ) : (
            <FcCollapse onClick={viewTechnical} />
          )}
        </CategoryNameDiv>
        <DetailedDiv style={{ display: technical ? "flex" : "none" }}>
          <u>Round Table:</u>
          <ul>
            Current challenges of publishing peer reviewed articles: Testimonies
            of senior professors and recipes of success for junior faculty,
            Friday, August 30, 2019 at the Joint International Conference of
            CEDIMES Institute USA, Africa Business and Entrepreneurship Research
            Society and IPAGEF, August 30—September 1, 2019, State University of
            New York, Plattsburgh, NY, USA.
          </ul>
          <u>Panel Discussion:</u>
          <ul>
            Jobs and Publication Markets, Sunday, August 4, 2019 at the Ottawa
            Research Conference, University of Ottawa, August 3-4, 2019, Ottawa,
            Canada.
          </ul>
        </DetailedDiv>
      </div>
    </>
  );
};

const CategoryNameDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const DetailedDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 5%;

  ul {
    background-color: #f0f2f2;
    list-style-position: inside;
    padding: 10px;
    margin-bottom: 20px;

    list-style-type: none;
  }

  span {
    margin-left: 20px;
  }
`;

export default Meetings;
