import styled from "styled-components";
import { NavLink } from "react-router-dom";

//----------------------//
//---Header Component---//
//----------------------//
const Header = () => {
  return (
    <HeaderDiv>
      <HomeLink to={"/"}>Basu Sharma, Ph.D.</HomeLink>

      <div>
        {/* <PageLink to={"/"}>Home</PageLink> */}
        <PageLink to={"/about"}>About</PageLink>
        <PageLink to={"/author"}>Author</PageLink>
        <PageLink to={"/editor"}>Editor</PageLink>
        <PageLink to={"/professional"}>Professional</PageLink>
        <PageLink to={"/records"}>Digital Public Records</PageLink>
        <PageLink to={"/blog"}>Blog</PageLink>
        <PageLink to={"contact"}>Contact</PageLink>
      </div>
    </HeaderDiv>
  );
};

const HeaderDiv = styled.div`
  width: 100%;
  height: 120px;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #97afa3;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 1em;
  letter-spacing: 2px;

  div {
    display: flex;
    gap: 30px;
  }
`;

const PageLink = styled(NavLink)`
  font-weight: 400;
  color: #f8f8f8;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #404f4a;
    transition: all 0.2s ease-in-out;
  }

  &.active {
    color: #404f4a;
  }
`;

const HomeLink = styled(NavLink)`
  font-size: 22px;
  font-weight: 400;
  color: #f8f8f8;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #404f4a;
    transition: all 0.2s ease-in-out;
  }
`;

export default Header;
