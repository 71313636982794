const Articles = () => {
  return (
    <ol reversed>
      <li>
        Adhikari, A., Bhattarai, K., and Sharma, B. 2022. A review of
        environmental vulnerabilities related to Nepal’s graduation process from
        least developed to a developing country status.{" "}
        <i>Journal of Foreign Affairs</i>, 2:1, 19-38. DOI:
        10.3126/j0fa.v2i01.43868.
      </li>
      <li>
        BasuSharma. 2021. Covid-19 and recalibration of FDI regimes: convergence
        or divergence? <i>Transnational Corporations Review</i>, 13:1, 62-73,
        DOI:{" "}
        <a
          href="https://www.tandfonline.com/doi/abs/10.1080/19186444.2021.1890433?journalCode=rncr20"
          target="_blank"
        >
          10.1080/19186444.2021.1890433
        </a>
      </li>
      <li>
        Rashid, Muhammad and Sharma, B. 2017. The role of remittance-induced
        credit expansion in a short-run Keynesian Macroeconomic model.{" "}
        <i>Journal of Comparative International Management</i>, Vol. 20, No. 1,
        pp. 43-53.
      </li>
      <li>
        Sharma, B. and Abekah, Joe. 2017. Foreign direct investment, foreign
        aid, and incomes inequality: Empirical insights from African and South
        American countries. <i>Transnational Corporations Review</i>, Vol. 9,
        No. 1, pp. 1-7.{" "}
        <a
          href="http://dx.doi.org/10.1080/19186444.2017.1290912"
          target="_blank"
        >
          DOI: 10.1080/19186444.2017.1290912
        </a>
        .
      </li>
      <li>
        Sharma, Basu. 2017. Socio-economic problems of remittance economy: The
        case of Nepal. <i>Journal of Advanced Management Science</i>, Vol. 5,
        No. 4, [Doi: 10.18178/joams.5.4.285-290].
      </li>
      <li>
        Sharma, Basu. 2016. Management challenges arising from disruptive
        political changes in Nepal,{" "}
        <i>Journal of Business and Economic Policy</i>, Vol. 3, No. 4, pp.13-17.
      </li>
      <li>
        Sharma, Basu, Gopalan Srinivasan, Francisco Arcelus and Murari Subedi.
        2014. Foreign capital and human development in Asia.{" "}
        <i>Entrepreneurship and Management</i> (Social Sciences Academy of
        Poland), Vol. 15, No. 11, pp.19-26.
      </li>
      <li>
        Rashid, M., Drira, M. and Sharma, Basu. 2014. Capital structure in the
        context of CEO’s rent extraction. <i>Corporate Ownership and Control</i>
        , Vol. 11, Issue 2, pp. 281-288. ({" "}
        <a href="http://dx.doi.org/10.22495/cocv11i2c2p5" target="blank">
          http://dx.doi.org/10.22495/cocv11i2c2p5
        </a>{" "}
        ).
      </li>
      <li>
        Sharma, Basu and M. Rashid. 2013. The Internet-Induced Economic and
        Business Issues. <i>Entrepreneurship and Management</i> (Social Sciences
        Academy of Poland), Vol. 14, No. 8, pp.63-72.
      </li>
      <li>
        Gani, A. and Basu Sharma. 2013. Remittances and Credit provided by the
        Banking Sector in Developing Countries.{" "}
        <i>International Review of Business Research Papers</i>, Vol. 4, No. 3,
        pp. 85-98.
      </li>
      <li>
        Sharma, Basu, M. Drira and M. Rashid. 2013. “The Influence of Social
        Dominance Orientation and Hegemonic Power on CEO Excess Compensation,”
        <i>World Review of Business Research</i>, Vol. 3, No. 1, pp. 114-124.
      </li>
      <li>
        Rashid, M. , M. Drira and Basu Sharma. 2012. “Critical evaluation of
        solutions to the too big to fail problem,”{" "}
        <i>The Journal of American Academy of Business</i>, Vol. 18, No. 1, pp.
        114-123.
      </li>
      <li>
        Sharma, Basu. 2012. The effects of creativity and culture on trade union
        density. <i>Atlantic Economic Journal</i>, Vol. 40, No. 1.
      </li>
      <li>
        Sharma, Basu and Azmat Gani. 2011. “Creativity, culture and trade union
        density in developed market economies,” <i>Psychology Research</i>, Vol.
        1, No. 2, pp. 106-113. Doi: 10.17265/2159-5542/2011.02004.
      </li>
      <li>
        Sharma, Basu and Joe Abekah. 2008. “Foreign Direct Investment and
        Economic Growth of Africa,” <i>Atlantic Economic Journal</i>, Vol. 36,
        No. 1, pp. 117-118.
      </li>
      <li>
        Rashid, M. and Sharma, Basu. 2008. “Interest Rates and Environmental
        Pollution,” <i>Journal of Comparative International Management</i>, Vol.
        11, No. 1, pp. 43-59.
      </li>
      <li>
        Chew, Irene K.H. and Basu Sharma. 2005. "The Effects of Culture and HRM
        Practices on Firm Performance: Empirical Evidence from Singapore."
        <i>International Journal of Manpower</i>, Vol. 26, No. 6, pp. 560-581.
      </li>
      <li>
        Arcelus, Francisco, Basu Sharma and Gopalan Srinivasan. 2005. "Foreign
        Capital Flows and the Efficiency of the HDI Dimensions,"{" "}
        <i>Global Economy Journal</i> ,Vol. 5: No. 1,
        {/* http://www.bepress.com/gej/vol5/iss1/4. */}
      </li>
      <li>
        Arcelus, Francisco, Basu Sharma and Gopalan Srinivasan. 2005. The Human
        Development Index Adjusted for Efficient Resource Utilization.{" "}
        <i>Research Paper No. 2005/08</i>, World Institute for Development
        Economics Research, United Nations University.
      </li>
      <li>
        Arcelus, Francisco, Basu Sharma and Gopalan Srinivasan. 2005. "Assessing
        the information content of the technology achievement index in the
        presence of the human development index." <i>Economics Bulletin</i> ,
        Vol. 15, No. 4, pp. 1-5.
        {/* URL: http://www.economicsbulletin.com/2005/volume15. */}
      </li>
      <li>
        Basu Sharma and Azmat Gani. 2004. "The Effects of Foreign Direct
        Investment on Human Development", <i>Global Economy Journal</i>: Vol. 4:
        No. 2, Article 9.
        {/* http://www.bepress.com/gej/vol4/iss2/9 . */}
      </li>
      <li>
        Arcelus, Francisco J., Basu Sharma and Irene K.H. Chew. 2004. “On the
        incidence of wage stickiness in Malaysia and Singapore,”{" "}
        <i>Asian Profile</i>, Vol. 36, no. 5, pp. 429-440.
      </li>
      <li>
        Otuteye, Eben and Basu Sharma, 2004. “A Transaction cost and
        transformational generative model of aggregate entrepreneurship,”
        <i>Journal of Entrepreneurship</i>, Vol. 13, No. 2, pp. 153-165.
      </li>
      <li>
        Gani, Azmat and Basu Sharma, 2003. “The effect of information technology
        achievement and diffusion on foreign direct investment,”{" "}
        <i>Perspectives on Global Development and Technology</i>, Vol. 2, No. 2,
        pp. 161-178.
      </li>
      <li>
        Sharma, Basu. 2003. “Hofstede's measures of national culture and social
        progress,” <i>Psychological Reports</i>, Vol. 92, pp. 1199-1202.
      </li>
      <li>
        Sharma, Basu and Anthony E. Smith. 2001. “Firm Performance and Executive
        Compensation in Australia and Canada,”{" "}
        <i>Journal of Comparative International Management</i>, Vol. 4, No. 2,
        pp. 87-99.
      </li>
      <li>
        Bansal, Harvir, Morris B. Mendelson and Basu Sharma. 2001. “The Impact
        of Internal Marketing Actitivies on External Marketing Outcomes,”
        <i>Journal of Quality Management</i>, Vol. 6, pp. 61-76.
      </li>
      <li>
        Sharma, Basu and Aaliya Fayyaz. 2000. “The Effect of Hegemonic Power on
        Executive Compensation,”{" "}
        <i>International Journal of Commerce and Management</i>, Vol. 10, No.
        3&4, pp. 79-91.
      </li>
      <li>
        Sharma, Basu. 2000. “Industrial Relations Strategies in Times of Crisis
        and Recovery in ASEAN,”{" "}
        <i>Research and Practice in Human Resource Management</i>, Vol. 8, No.
        1, pp. 41-62.
      </li>
      <li>
        Sharma, Basu. 1999. “Distinguishing the Best from the Rest,”{" "}
        <i>Journal of Comparative International Management</i>, Vol. 2, No. 1,
        pp. 3-8.
      </li>
      <li>
        Sharma, Basu. 1999. “Hegemonic Power and Canadian Corporate Executives’
        Compensation,” <i>Perceptual and Motor Skills</i>, Vol. 88, pp. 167-168.
      </li>
      <li>
        Sharma, Basu. 1998. “Economic Growth and Suicide and Homicide Rates
        Revisited,” <i>Perceptual and Motor Skills</i>, Vol. 86, pp. 561-562.
      </li>
      <li>
        Sharma, Basu. 1997. “Globalization and Trade Union Growth in Canada,”
        <i>Sri Lankan Journal of Management</i>, Vol. 2, No. 4, pp. 369-374.
      </li>
      <li>
        Sharma, Basu and Judy Roy. 1996. “Aspects of the Internationalization of
        Management Educations,” <i>Journal of Management Development</i>, Vol.
        15, No. 1, pp. 5- 13.
      </li>
      <li>
        Chalykoff, John, Basu Sharma, and M. Williams. 1995. “Managing Quality
        for Organizational Effectiveness: Empirical Evidence From Four Sawmills
        in Atlantic Canada,” <i>Total Quality Management Journal</i>, Vol. 6,
        No. 1, pp. 51-59.
      </li>
      <li>
        Sharma, Basu, and Lan Luh Luh. 1994. “Labour Market Flexibilities as HRM
        Strategies – The Example of Singapore,” <i>Journal of Asian Business</i>
        , Vol. 10, No. 1, pp. 61-77.
      </li>
      <li>
        Staber, Udo, and Basu Sharma. 1994. “The Employment Regimes of
        Industrial Districts: Promises, Myths, and Realities,”{" "}
        <i>
          Industrielle Beziehungen: The German Journal of Industrial Relations
        </i>
        , Jg. 1, Heft 4, pp. 321-346.
      </li>
      <li>
        Sharma, Basu. 1994. “Compensation Strategies and Competitiveness: Asia
        vs. North America,” <i>South Asian Journal of Management</i>, Vol. 1,
        No. 3, pp. 21-32.
      </li>
      <li>
        Sharma, Basu and Irene Chew. 1992.”The Role of Compensation Policies in
        Singapore's Competitiveness,”{" "}
        <i>Asia Pacific Journal of Human Resources Management</i>, Vol. 30, pp.
        16-24.
      </li>
      <li>
        Lin, Edward, Basu Sharma, and Eben Otuteye. 1992. “An ARIMA Model for
        Canadian Union Membership Growth, 1911-1985,” <i>Applied Economics</i>,
        Vol. 24, pp. 1035-1041.
      </li>
      <li>
        Sharma, Basu. 1992. “Compensation Strategies and Export Performance of
        Small Manufacturing Firms in Atlantic Canada,”{" "}
        <i>Journal of Small Business and Entrepreneurship</i>, Vol. 9, No. 21,
        pp. 22-29.
      </li>
      <li>
        Rashid, M., and Basu Sharma. 1991. “Optimal Sharing Ratio in a
        Profit-Sharing Arrangement,”{" "}
        <i>Canadian Journal of Administrative Sciences</i>, Vol. 8, No. 4, pp.
        259-266. [Nominated by the Finance area for the 1991 CJAS Best Paper
        Award]
      </li>
      <li>
        Rahim, A., and Basu Sharma. 1991. “Sex Differences in Performance on a
        Combined Manual and Decision Task,” <i>Perceptual and Motor Skills</i>,
        Vol. 73, pp. 695-700.
      </li>
      <li>
        Sharma, Basu, and Peter Sephton. 1991. “The Determinants of Union
        Membership Growth in Taiwan,” <i>Journal of Labour Research</i>, Vol.
        XII, No. 4, pp. 429-437.
      </li>
      <li>
        Sharma, Basu, and L. Steier. 1990. “Management Education and Development
        in Canada: Past, Present and Future,”{" "}
        <i>Canadian Journal of Administrative Sciences</i>, Vol. 7, No. 1, pp.
        1-10.
      </li>
      <li>
        Sharma, Basu. 1989. “Union Growth in Malaysia and Singapore,”{" "}
        <i>Industrial Relations</i>, Vol. 28, No.3, pp. 446-458.
      </li>
      <li>
        Sharma, Basu. 1989. “The Effects of Strikes on Economic Growth,”
        <i>Atlantic Economic Journal</i>, Vol. , No. , pp. . Doi:
        10.1007/BF02303277
      </li>
      <li>
        Arcelus, F., and Basu Sharma. 1989. “A Discrete-Choice Approach to the
        Modelling of the Intensity of Participation in Union Activities,”
        <i>Canadian Journal of Administrative Sciences</i>, Vol. 6, No. 4, pp.
        36-44.
      </li>
      <li>
        Sharma, Basu. 1989. “Korean Trade Union Growth During the Period
        1962-1984,” <i>Economics Letters</i>, Vol. 31, No. 1, pp. 105-108.
      </li>
      <li>
        Sharma, Basu, and Hem Jain. 1989. “Strategies for Management of
        Industrial Relations in India and Indonesia,” <i>Asian Profile</i>, Vol.
        17, No. 6, pp. 523-531.
      </li>
      <li>
        Sharma, Basu, and M. Rashid. 1988. “Profit Sharing, Small Business and
        Employment,” <i>Journal of Small Business and Entrepreneurship</i>, Vol.
        V, No. 3, pp. 61- 67.
      </li>
      <li>
        Sharma, Basu. 1987. “Determinants of Membership Participation in Union
        Activity,” <i>Indian Journal of Industrial Relations</i>, Vol. 22, No.
        4, pp. 454-465.
      </li>
      <li>
        Sharma, Basu. 1987. “Human Resource as a Strategic Variable in Venture
        Creation,” <i>Journal of Small Business and Entrepreneurship</i>, Vol.
        4, No. 3, pp. 32-35. (Reprinted in Raymond W. Y. Kao, ed.,{" "}
        <i>Readings in Entrepreneurship and Small Business Development</i>{" "}
        [Toronto: The Ryerson Centre of Entrepreneurship, 1988], pp. 108-111).
      </li>
      <li>
        Sharma, Basu. 1986. “Regionalism and ASEAN Industrial Relations,”{" "}
        <i>ASEAN Economic Bulletin</i>, Vol. 3, No. 2, pp. 148-154. [Abstracted
        in the
        <i>Asian-Pacific Economic Literature</i>, Vol. 1, No. 1 (May 1987)].
      </li>
      <li>
        Sharma, Basu. 1986. “Foreign Capital and Economic Growth of Developing
        Countries: Debt vs. Equity,”{" "}
        <i>Studies in Comparative International Development</i>, Vol. 21, No. 1,
        pp. 60-70.
      </li>
      <li>
        Sharma, Basu. 1986. “Changing Industrial Relations Patterns in ASEAN,”
        <i>Euro-Asia Business Review</i>, Vol. 5, No. 2, pp. 44-47.
      </li>
      <li>
        Sharma, Basu. 1984. “Multinational Corporations and Industrialization in
        Southeast and East Asia.” <i>Contemporary Southeast Asia</i>, Vol. 6,
        No. 2, pp. 159-171.
      </li>
      <li>
        Sharma, Basu, and Koji Taira. 1982. “Foreign Direct Investment, Foreign
        Aid, Economic Growth and Employment in Selected Asian Countries.”{" "}
        <i>Asian Economies</i>, No. 42, pp. 5-17.
      </li>
    </ol>
  );
};

export default Articles;
